import React from "react";
import { ThemeProvider } from "styled-components";

const themes = {
  blue: {
    mainColor: "#0058d7",
  },
  red: {
    mainColor: "#C93030",
  },
  green: {
    mainColor: "#389f3a",
  },
};

const Theme = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const paramsValue = urlParams.get("theme");
  const theme = themes?.[paramsValue] ? paramsValue : "red";
  return <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>;
};

export default Theme;
