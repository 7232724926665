import { Navigate } from "react-router-dom";
import { FormProvider } from "context/useForm";
import SmartForm from "pages/SmartForm";

const routes = [
  {
    path: "/:step",
    element: (
      <FormProvider>
        <SmartForm />
      </FormProvider>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/1" />,
  },
];

export const getRoutes = () => routes;
