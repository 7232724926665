import { useEffect } from "react";
import { motion } from "framer-motion";

const variants = {
  right: {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 },
  },
  left: {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 50 },
  },
};

const AnimatedScreen = ({
  children,
  screenKey,
  direction = "right",
  ...restProps
}) => {
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView();
  }, [screenKey]);

  return (
    <motion.div
      key={screenKey}
      variants={variants[direction]}
      initial="initial"
      animate="animate"
      exit="exit"
      style={{ flex: 1 }}
      {...restProps}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedScreen;
