import styled from "styles";

export const MainContainer = styled.main`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 76.8rem;
  margin: 0 auto;
  padding: 2rem 1.6rem 4rem;
  display: flex;
  flex-direction: column;
  /* overflow-x: hidden; */
  /* overflow-y: auto; */

  .primary-logo {
    margin: 4rem 0rem;
    svg {
      width: 13.6rem;
      height: 3.4rem;
    }
  }

  .relative-bl {
    position: relative;
  }

  .loading {
    display: flex;
    justify-content: center;
  }

  .secondary-logo {
    margin: 2rem 0rem 4rem;
    display: flex;
    justify-content: center;
    svg {
      width: 10.3rem;
      height: 2.6rem;
    }
  }
`;
