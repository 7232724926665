import { Suspense } from "react";
import { AnimatePresence } from "framer-motion";
import AnimatedScreen from "components/AnimatedScreen";

import ProgressIndicator from "components/ProgressIndicator";
import BackBtn from "components/BackBtn/BackBtn";

import { Logo } from "assets/icons";
import { MainContainer } from "styles/app.style";
// import { CustomerProvider } from "context/useCustomer";
import { useForm } from "context/useForm";
// import Spinner from "components/Spinner";

function SmartForm() {
  const { onPrevStep, step, RenderStep, stepCount } = useForm();

  const showProgressIndicator = step <= stepCount;
  const showBackBtn = 2 < step && step <= stepCount;
  return (
    // <MainContainer className={step === 0 ? "extra-class" : null}>

    <MainContainer>
      <div className="relative-bl">
        {showBackBtn && <BackBtn onClick={onPrevStep} />}
        <div className={"secondary-logo"}>
          <Logo />
        </div>
      </div>

      {showProgressIndicator && (
        <div style={{ marginBottom: "3.2rem" }}>
          <ProgressIndicator />
        </div>
      )}

      <AnimatePresence>
        <Suspense fallback={null}>
          <AnimatedScreen screenKey={step}>{<RenderStep />}</AnimatedScreen>
        </Suspense>
      </AnimatePresence>
    </MainContainer>
  );
}

export default SmartForm;
