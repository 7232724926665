import React from "react";
import { BackBtnStyle } from "styles/styles";
import { SmallLeftArrowIcon } from "assets/icons";

const BackBtn = ({ onClick }) => {
  return (
    <BackBtnStyle onClick={onClick}>
      <SmallLeftArrowIcon />
      Back
    </BackBtnStyle>
  );
};

export default BackBtn;
