import { createContext, lazy, useContext, useState } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";
// const IntroScreen = lazy(()=> import("screens/IntroScreen"));
// const JoinOurTeamScreen = lazy(()=> import("screens/JoinOurTeamScreen"));
const ExclusiveServiceScreen = lazy(() =>
  import("screens/ExclusiveServiceScreen")
);
const ShareExpFirstScreen = lazy(() => import("screens/ShareExpFirstScreen"));
const ShareExpSecondScreen = lazy(() => import("screens/ShareExpSecondScreen"));
const ShareExpThirdScreen = lazy(() => import("screens/ShareExpThirdScreen"));
const GetSolutionsScreen = lazy(() => import("screens/GetSolutionsScreen"));
// const InterviewScreen = lazy(()=> import("screens/InterviewScreen"));
const CdlScreen = lazy(() => import("screens/CdlScreen"));
const SocialMediaScreen = lazy(() => import("screens/SocialMediaScreen"));
const TermsAndConditions = lazy(() => import("screens/TermsAndConditions"));

const forms = {
  1: ExclusiveServiceScreen,
  2: ShareExpFirstScreen,
  3: ShareExpSecondScreen,
  4: ShareExpThirdScreen,
  5: CdlScreen,
  6: GetSolutionsScreen,
  7: SocialMediaScreen,
  8: TermsAndConditions,
};
const stepCount = Object.keys(forms).length - 1;

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const navigate = useNavigate();
  const { step } = useParams();
  const [customer, setCustomer] = useState({
    fullName: "",
    phone: "",
    email: "",
    customerId: "",
  });

  const iStep = Number(step);
  const invalidStep =
    !forms[iStep] || (1 < iStep && iStep < 8 && !customer.customerId);

  if (invalidStep) {
    return <Navigate to="/1" replace={true} />;
  }

  const updateCustomer = (values) => {
    setCustomer((prev) => ({ ...prev, ...values }));
  };

  const handleNextStep = (newStep) => {
    navigate(`/${typeof newStep === "number" ? newStep : iStep + 1}`);
  };
  const handlePrevStep = (newStep) => {
    navigate(`/${typeof newStep === "number" ? newStep : iStep - 1}`);
  };

  const RenderStep = forms[iStep];
  return (
    <FormContext.Provider
      value={{
        customer,
        setCustomer: updateCustomer,
        step: iStep,
        RenderStep,
        stepCount,
        onNextStep: handleNextStep,
        onPrevStep: handlePrevStep,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useForm = () => useContext(FormContext);
