import { useForm } from "context/useForm";
import { ProgressIndicatorContainer } from "styles/styles";

const ProgressIndicator = () => {
  const { step, stepCount } = useForm();
  const progress = (step / stepCount) * 100;
  return (
    <ProgressIndicatorContainer>
      <div className="indicator-wrap">
        <div className="indicator" style={{ width: `${progress}%` }}></div>
      </div>
      <span>
        {step}/{stepCount}
      </span>
    </ProgressIndicatorContainer>
  );
};

export default ProgressIndicator;
